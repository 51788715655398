import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../partials/Header';
import PageIllustration from '../partials/PageIllustration';
import HeroHome from '../partials/HeroHome';
import HomeZigzag from '../partials/HomeZigzag';
import FeaturesHome from '../partials/FeaturesHome';
import Newsletter from '../partials/Newsletter';
import Footer from '../partials/Footer';

function Home() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Helmet>
        <title>Agile, Automotive Cybersecurity - ThreatAgility</title>
        <meta name="description" property="og:description" content="Agile, intelligent cybersecurity, woven into the lifecycle of connected vehicles. ThreatAgility’s unique AI-driven platform enables self-aware, cybersecure vehicles." />
        <meta name="title" property="og:title" content="Agile, Automotive Cybersecurity - ThreatAgility" />
        <meta name="image" property="og:image" content={window.location.origin+require('../images/TAS.jpg').default} />
      </Helmet>
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page illustration */}
        <div className="z-10 relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
          <PageIllustration />
        </div>

        {/*  Page sections */}
        <HeroHome />
        <HomeZigzag />
        <FeaturesHome />
        {/* <Tabs /> */}
        {/* <Target /> */}
        {/* <News /> */}
        <Newsletter />

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default Home;