import React from 'react';

function Ring({times}) {
  return (
    // <div style={{transformOrigin: "top left", transform: "scale(1.9) translate(10px, -40px)"}} className="z-0 absolute lg:block pointer-events-none" >
      <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160.41 160.41">
    <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
            <path class="cls-1" d="M80.21,0a80.21,80.21,0,1,0,80.2,80.21A80.21,80.21,0,0,0,80.21,0ZM84,154.49a74.28,74.28,0,1,1,74.28-74.28A74.28,74.28,0,0,1,84,154.49Z" fill="#ee2d2c"/>
        </g>
    </g>
    </svg>
    </div>
  )
}


function Leadership() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 mt-24 sm:mt-16 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="z-10 relative max-w-2xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4">Driven by Experience</h2>
            <br/>
            <p className="text-xl text-gray-200">
              ThreatAgility founders Patrick Laird and Peter Wesley have combined their expertise in automotive
              engineering and cybersecurity to forge a new approach to automotive cybersecurity.
              <br/><br/>
              With deep cross-cultural experience and strong working relationships throughout the global automotive 
              and cybersecurity communities, Patrick and Peter have built ThreatAgility with a mission to deliver 
              an agile, intelligent and accessible cybersecurity platform. 
              </p>
          </div>

          {/* Leadership Team Members */}
          <div className="z-10 min-w-2xl flex flex-col justify-center " data-aos-id-leadership>
            {/* 1st member */}
            <div className="relative flex flex-col sm:flex-row justify-center" data-aos="fade-up" data-aos-anchor="[data-aos-id-leadership]">
              <div className="min-w-xl flex flex-col items-center">
              {/* <div style={{transformOrigin: "top left", transform: "scale(1.9) translate(10px, -40px)"}} className="z-20 absolute lg:block pointer-events-none" > */}
                
                <div style={{transform: "scale(1) translate(-3px, 11px)"}} className="z-20 absolute lg:block pointer-events-none" >
                  <svg width="160" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160.41 160.41">
                    
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path d="M80.21,0a80.21,80.21,0,1,0,80.2,80.21A80.21,80.21,0,0,0,80.21,0ZM84,154.49a74.28,74.28,0,1,1,74.28-74.28A74.28,74.28,0,0,1,84,154.49Z" fill="#dd2d2c"/>
                    </g>
                    
                  </svg>
                </div>
                <img className="align-top rounded-full mt-4 mb-4" src={require('../images/aboutus/pl.jpg').default} width="150" height="150" alt="PL Picture" />
                {/* <div className="text-gray-500 text-center mb-1">Co-founder</div> */}
                {/* <a className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="#0">@laraamprecht</a> */}
              </div>
              <div className="max-w-xl sm:pl-16">
                <h3 className="h3 mb-4 text-center sm:text-left font-medium mb-1">Patrick Laird</h3>
                <p className="text-m text-center sm:text-left text-gray-200">
                  Patrick Laird has cultivated a rich network and extensive professional experience
                  over two decades working throughout Europe, the UK, Japan and China.  
                  In senior engineering and management roles Patrick has established a portfolio including
                   deep experience of electronic design, and development processes for
                  turnkey projects utilizing safety, security, and quality requirements.<br/><br/>
                   
                  Patrick has worked as an automotive systems consultant in China since 2012 building a 
                  strong community network through his working relationships with local OEM’s, tier-1
                  suppliers, and engineering service providers across the region. 
                </p>
              </div>
            </div>
            
            {/* 2nd member */}
            <div className="relative pt-16 flex flex-col sm:flex-row justify-center" data-aos="fade-up" data-aos-anchor="[data-aos-id-leadership]">
              <div className="min-w-xl flex flex-col items-center">
              <div style={{transform: "scale(1) translate(-3px, 11px)"}} className="z-20 absolute lg:block pointer-events-none" >
                  <svg width="160" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160.41 160.41">
                    
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path d="M80.21,0a80.21,80.21,0,1,0,80.2,80.21A80.21,80.21,0,0,0,80.21,0ZM84,154.49a74.28,74.28,0,1,1,74.28-74.28A74.28,74.28,0,0,1,84,154.49Z" fill="#dd2d2c"/>
                    </g>
                    
                  </svg>
                </div>
                <img className="rounded-full mt-4 mb-4" src={require('../images/aboutus/pw.jpg').default} width="150" height="150" alt="PW Picture" />
                {/* <div className="text-gray-500 text-center mb-1">Co-founder</div> */}
                {/* <a className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="#0">@laraamprecht</a> */}
              </div>
              <div className="max-w-xl sm:pl-16">
                <h3 className="h3 mb-4 text-center sm:text-left font-medium mb-1">Peter Wesley</h3>
                <p className="text-m text-center sm:text-left text-gray-200">
                  Peter Wesley's 25-year career as a technology specialist has included the finance, telecommunications,
                  and manufacturing sectors in Australia, Singapore, the USA and China, where he has developed 
                  extensive experience in policy and governance, architecture and risk assessment, threat intelligence, 
                  and security vulnerability research. Enriched by his diverse professional journey, Peter is equipped 
                  with broad cross-cultural experiences and strong working relationships across the world and in many industries. 
                  <br/><br/>
                  Over the past seven years Peter has worked at the cutting edge of telecommunications and 
                  electrical vehicles in China for Huawei, Byton, and Bird Rides.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Leadership;