import React from 'react';

function ServicesBlocks() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 mb-16">
        <div className="py-6 md:py-10 border-t border-gray-800">

          {/* Section header */}
          <div className="max-w-lg md:max-w-3xl mx-auto text-center pb-12 md:pb-16" data-aos="fade-up">
            <h3 className="h3 mb-0">Made-to-measure Cybersecurity Services</h3>
            {/* <p className="text-xl text-gray-400">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> */}
          </div>

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-16 items-start md:max-w-2xl lg:max-w-none" data-aos-id-blocks>

            {/* 1st item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]">
              <svg className="w-24 h-24 mb-4" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
                <rect className="fill-current text-red-600" width="128" height="128" rx="128" />
                <image href={require('../images/cybermgmtW.png').default} x="13" y="15" width="100" height="100" alt="CS Management Logo" />
              </svg>
              <h4 className="text-center h4 mb-2">Cybersecurity Management</h4>
              <p className=" text-gray-400 text-center">
                Starting with a quantified assessment of cybersecurity processes, 
                structures, and planning, ThreatAgility prepares a 
                comprehensive and measurable plan for cybersecurity maturity,
                prioritized for both product and regulatory requirements.  
              </p>
            </div>

            {/* 2nd item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]">
              <svg className="w-24 h-24 mb-4" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
                <rect className="fill-current text-red-600" width="128" height="128" rx="128" />
                <image href={require('../images/cybertrainW.png').default} x="20" y="20" width="85" height="85" alt="CS Training Logo" />
              </svg>
              <h4 className="text-center h4 mb-2">Cybersecurity Training</h4>
              <p className="text-gray-400 text-center">
                Driven by our cybersecurity maturity strategy, ThreatAgility's tailored 
                cybersecurity training  delivers just in time, fit for purpose training 
                that generates cybersecurity capability exactly when and where it is most effective.
              </p>
            </div>
            

            {/* 3rd item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]">
              <svg className="w-24 h-24 mb-4" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
                <rect className="fill-current text-red-600" width="128" height="128" rx="128" />
                <image href={require('../images/cyberregW.png').default} x="18" y="20" width="90" height="90" alt="Regulation Logo" />
              </svg>
              <h4 className="text-center h4 mb-2">Regulatory Compliance</h4>
              <p className=" text-gray-400 text-center">
                ThreatAgility's cybersecurity modeling also provides direct assessment 
                of regulatory compliance at organisational and product levels. This allows 
                rapid impact assessment of new markets and emerging cybersecurity and privacy regulations.
              
              </p>
            </div>
            

            {/* 4th item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]">
              <svg className="w-24 h-24 mb-4" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
                <rect className="fill-current text-red-600" width="128" height="128" rx="128" />
                <image href={require('../images/cyberarchW.png').default} x="14" y="14" width="100" height="100" alt="Design Architecture Logo" />
              </svg>
              <h4 className="text-center h4 mb-2">Design and Architecture</h4>
              <p className="text-gray-400 text-center">
                Uniting elite technical capabilities with mature automotive know-how, 
                and supported by our innovative platform,  
                ThreatAgility's design specialists deliver leading-edge cybersecurity 
                even under demanding project schedules.  
              </p>
            </div>

            {/* 5th item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]">
              <svg className="w-24 h-24 mb-4" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
                <rect className="fill-current text-red-600" width="128" height="128" rx="128" />
                <image href={require('../images/cybertestW.png').default} x="27" y="24" width="80" height="80" alt="Testing Logo" />
              </svg>
              <h4 className="text-center h4 mb-2">Strategic Testing</h4>
              <p className="text-gray-400 text-center">
                Our high-fidelity threat modelling enables strategic early testing of
                critical controls, and clear scope for specialized penetration testing. 
                Timely and well scoped testing ensures issues are detected 
                when they can be resolved with least expense. 
              </p>
            </div>

            {/* 6th item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]">
              <svg className="w-24 h-24 mb-4" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
                <rect className="fill-current text-red-600" width="128" height="128" rx="128" />
                <image href={require('../images/cyberlabW.png').default} x="6" y="8" width="120" height="120" alt="CS Lab Logo" />
              </svg>
              <h4 className="text-center h4 mb-2">Cybersecurity Lab Setup</h4>
              <p className="text-gray-400 text-center">
                Drawing on our comprehensive knowledge of cybersecurity threats, 
                specialized test equipment catalog, and experience integrating security testing tools, to help our customers build their own, 
                tailor-made cybersecurity testing capabilities.
              </p>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}

export default ServicesBlocks;
