import React from 'react';

function FeaturesHome() {
  return (
    <section>
      
      <div className="max-w-5xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
        
          {/* Items */}
          <div className="grid gap-20" data-aos-id-features-home>

            {/* Item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Image */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-up" data-aos-anchor="[data-aos-id-features-home]">
                <div className="relative">
                  <img className="md:max-w-none" src={require('../images/carwfd.jpg').default} width="540" height="520" alt="Features illustration" />
                  <svg className="absolute inset-0 max-w-full mx-auto md:max-w-none h-auto" width="540" height="520" viewBox="-0 120 540 520" xmlns="http://www.w3.org/2000/svg">
                    <g className="fill-current text-red-700">
                      <circle className="pulse" cx="270" cy="260" r="10" />
                      <circle className="pulse pulse-1" cx="270" cy="260" r="30" />
                      <circle className="pulse pulse-2" cx="270" cy="260" r="30" />
                      {/* <circle className="pulse pulse-3" cx="270" cy="260" r="30" /> */}
                    </g>
                  </svg>
                  <svg className="absolute inset-0 max-w-full mx-auto md:max-w-none h-auto" width="540" height="520" viewBox="-220 140 540 520" xmlns="http://www.w3.org/2000/svg">
                    <g className="fill-current text-yellow-600">
                      <circle className="pulse" cx="270" cy="260" r="10" />
                      <circle className="pulse pulse-2" cx="270" cy="260" r="20" />
                      <circle className="pulse pulse-3" cx="270" cy="260" r="20" />
                      {/* <circle className="pulse pulse-3" cx="270" cy="260" r="20" /> */}
                    </g>
                  </svg>
                  <svg className="absolute inset-0 max-w-full mx-auto md:max-w-none h-auto" width="540" height="520" viewBox="-70 40 540 520" xmlns="http://www.w3.org/2000/svg">
                    <g className="fill-current text-green-600">
                      <circle className="pulse" cx="270" cy="260" r="7" />
                      <circle className="pulse pulse-3" cx="270" cy="260" r="15" />
                      <circle className="pulse pulse-1" cx="270" cy="260" r="15" />
                      {/* <circle className="pulse pulse-3" cx="270" cy="260" r="20" /> */}
                    </g>
                  </svg>
                </div>
              </div>
              {/* Content */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-right" data-aos-anchor="[data-aos-id-features-home]">
              <h3 className="h3 mb-8">Self-aware Cybersecurity</h3>
      
                <div className="md:pr-4 lg:pr-12 xl:pr-16">
                  {/* <div className="font-architects-daughter text-xl text-purple-600 mb-2">Lighting fast workflow</div> */}
                  {/* <h3 className="h3 mb-3">The self-aware, cybersecure vehicle</h3> */}
                  <p className="text-xl text-gray-400 mb-4">
                    ThreatAgility's detection modules are trained on an integration of the high-fidelity models and attack simulations. 
                    Incorporated into vehicle systems, these modules create cybersecurity self-awareness. </p>
                  <p className="text-xl text-gray-400 mb-4">
                    Not limited to detecting known attacks, the vehicles also know where they are most vulnerable, allowing them to 
                    respond to new threats and anomalous events in proportion to the threat.
                  </p>
                  
                  {/* <div className="flex items-start mt-8">
                    <img className="rounded-full flex-shrink-0 mr-4" src={require('../images/features-avatar.jpg').default} width="40" height="40" alt="Features avatar" />
                    <div>
                      <blockquote className="text-gray-400 italic mb-3">"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing".</blockquote>
                      <div className="text-gray-700 font-medium">
                        <cite className="text-gray-200 not-italic">— Anastasia Dan</cite> - <a className="text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="#0">UX Board</a>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </section>
  );
}

export default FeaturesHome;
