import React from 'react';
import ReactDOM from 'react-dom';
//import render from 'react-snapshot'
import { BrowserRouter as Router } from "react-router-dom";
import App from './App';
import * as serviceWorker from './serviceWorker';

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(  
      <Router>
        <App />
      </Router>
    ,
    rootElement
  );
} else {
  ReactDOM.render(
      <Router>
        <App />
      </Router>
    ,
    rootElement
  );
}

// if (rootElement.hasChildNodes()) {
//   ReactDOM.hydrate(  
//     <React.StrictMode>
//       <Router>
//         <App />
//       </Router>
//     </React.StrictMode>,
//     rootElement
//   );
// } else {
//   ReactDOM.render(
//     <React.StrictMode>
//       <Router>
//         <App />
//       </Router>
//     </React.StrictMode>,
//     rootElement
//   );
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();
