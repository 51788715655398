import React from 'react';

function Career() {

  const positions=[ {
     title:'Automotive Cybersecurity Architect (Junior)', 
     description:"You'll be delivering cybersecurity requirements for systems and components of passenger vehicles, under guidance of a senior cybersecurity architect, working onsite in and around Shanghai",
     location:'Shanghai', package: 'package'
  },{
     title:'Embedded Testing Specialist', 
     description:"You'll be conducting sophisticated hardware and software attacks against embedded targets as part of a project testing team",
     location:'Shanghai; Global - Remote', package: 'package'
  },{
    title:'Front-end developer', 
    description:"You'll be joining our global platform team, and with your experience in current client-side frameworks, help expand the features of the ThreatAgility platform ",
    location:'Global - Remote', package: 'package'
  },{
    title:'Embedded Security Researcher', 
    description:"As an experienced embedded security researcher, you will be testing the boundaries of automotive cybersecurity, and helping shape and train the ThreatAgility platform",
    location:'Global - Remote', package: 'package'
  }
]

  return (
    <section>
      <div className="relative z-10 max-w-3xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-20">

          {/* Section header */}
          <h3 className="h4 mb-8">Open positions</h3>

          {/* Job list */}
          <div className="-my-3" data-aos-id-career>

            {/* 1st Item */}
            {positions.map((item, index) => (
              <div className="z-10 py-3" data-aos="fade-up" data-aos-anchor="[data-aos-id-career]">
                <div className="pb-6 border-b border-gray-800">
                  <div className="mb-2">
                    {/* <a className="flex justify-between items-center text-xl font-bold text-red-600 hover:text-gray-200 transition duration-150 ease-in-out" href="#0"> */}
                    <a className="flex justify-between items-center text-xl font-bold text-red-600 hover:text-gray-200 transition duration-150 ease-in-out"
                      href={"mailto:careers@threatagility.com?subject=Enquiry about " + item.title +  
                            " position&body=Hi,%0D%0A%0D%0AI'm interested in the " + item.title + " position at ThreatAgility, please send me details!%0D%0A%0D%0A(Also feel free to include your resume)"}>
                      <span>{item.title}</span>
                      <svg className="stroke-current flex-shrink-0 ml-2" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                        <g strokeWidth="2" fill="none">
                          <path d="M1 12h20" />
                          <path strokeLinecap="square" d="M14 5l7 7-7 7" />
                        </g>
                      </svg>
                    </a>
                  </div>
                  <div className="text-lg text-gray-400 mb-3">
                    {item.description}
                  </div>
                  <div className="text-gray-400 flex flex-wrap items-center">
                    <div className="inline-flex items-center">
                      <svg className="mr-2 flex-shrink-0" vidth="14" height="17" viewBox="0 0 14 17" xmlns="http://www.w3.org/2000/svg">
                        <path className="fill-current text-gray-300" d="M7 0C3.1 0 0 3.1 0 7c0 1.9.7 3.7 2.1 5 .1.1 4.1 3.7 4.2 3.8.4.3 1 .3 1.3 0 .1-.1 4.2-3.7 4.2-3.8 1.4-1.3 2.1-3.1 2.1-5 .1-3.9-3-7-6.9-7zm0 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
                      </svg>
                      <span>{item.location}</span>
                    </div>
                    {/* <span className="text-gray-700 mx-3"> - </span>
                    <div className="inline-flex items-center">
                      <svg className="mr-2 flex-shrink-0" vidth="14" height="16" viewBox="0 0 14 16" xmlns="http://www.w3.org/2000/svg">
                        <path className="fill-current text-gray-300" d="M9.5 3L11 0H3l1.5 3zM10 5H4c-2 1.458-4 4.235-4 6.4C0 12.573.5 16 7 16c6.495 0 7-3.431 7-4.6 0-2.165-2-4.942-4-6.4z" />
                      </svg>
                      <span>{item.package}</span>
                    </div> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Career;