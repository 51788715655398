import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { tags, authors, blogs } from '../blogs/blogindex.js'

export function BlogList( {tag, author, related}) {
  // const { index } = useParams();
  //console.log("tar:", tag, author, related)
  var results, title, selectag, selectauth
  if (tag) {
    selectag = tags[tag]
    results=blogs.filter(item => item.tags.includes(selectag) )
  } else if (author) {
    results=blogs.filter(item => item.author==author)
  } else if (related) {
    // related points to the blog post and here we search for related posts
    results=blogs.filter(item => {
      if (item==related)
        return false;
      var match=false
      related.tags.forEach(testtag => {
        item.tags.forEach(tag => {
          // console.log("tag, testtag", tag, testtag)
          if (tag==testtag) {
            match=true
          }
        })
      })
      return match  
    })
    title="Related articles"
  } else {
    results=blogs.filter(item => !item.featured)
    title="Latest articles"
  }
    
  if ((results.length==0) && (related)) {
      return(<></>)
  } else if (results.length==0) { 
    return (
      <div className="pt-20 pb-12 md:pt-20 md:pb-20"></div>
    )
  } else return (
    
    <div className="max-w-sm mx-auto md:max-w-none">

      <section className="relative">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pt-20 pb-12 md:pt-20 md:pb-20">
            {/*  Section title */}
            {selectag &&
              <nav className="items-center hidden md:flex md:flex-grow pb-6 mb-10 mt-1 border-b border-gray-700">
                <Link to="/blog" > 
                  <button>
                    <svg className="inline-flex align-middle w-9 h-10 text-white fill-current  hover:text-gray-300 transition duration-150 ease-in-out" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
                      <g id="line">
                        <circle cx="36" cy="36" r="36"/>
                        <polyline fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="5" points="42.1964,16.2048 22.8036,35.6651 42.1964,55.1254" />
                      </g>
                    </svg>
                  </button> 
                </Link>
                <ul className="text-lg flex mx-4 align-middle flex-grow justify-left flex-wrap items-center">
                  <li style={{pointerEvents: 'none'}} className={selectag.className}>{selectag.tag}</li>
                </ul>
              </nav>
            }
            {author &&
              <nav className="items-center hidden md:flex md:flex-grow pb-6 mb-10 border-b border-gray-700">
                <Link to="/blog" > 
                  <button>
                    <svg className="inline-flex align-middle w-9 h-10 text-white fill-current  hover:text-gray-300 transition duration-150 ease-in-out" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
                      <g id="line">
                        <circle cx="36" cy="36" r="36"/>
                        <polyline fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="5" points="42.1964,16.2048 22.8036,35.6651 42.1964,55.1254" />
                      </g>
                    </svg>
                  </button> 
                </Link>
                <ul className="text-lg flex align-middle flex-grow justify-left flex-wrap items-center">
                  <li><img className="items-center border-2 border-gray-700 rounded-full align-middle flex-shrink-0 mx-4" src={authors[author].image} width="47" height="45" alt={authors[author].name}/></li>
                  <li className="text-gray-200 text-2xl hover:text-gray-100 transition duration-150 ease-in-out">{authors[author].name}</li>
                  
              </ul>
            </nav>}
            {!author && !selectag &&
              <h4 className="h4 pb-6 mb-10 border-b border-gray-700" data-aos="fade-up">
                {title} 
              </h4>
            }
            <div className="grid gap-12 md:grid-cols-3 md:gap-x-6 md:gap-y-8 items-start">
              {results.map((blogItem, blogIndex) => {
                return (
                  <article key={blogIndex} className="flex flex-col h-full" data-aos="fade-up">
                    <header>
                      <Link to={"/blog-post" + "/" + blogItem.index} className="block mb-6">
                        <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                          <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out"
                            src={blogItem.keyImage} width="352" height="198" alt="Featured Image" />
                        </figure>
                      </Link>
                      <div className="mb-3">
                        <ul className="flex flex-wrap text-xs font-medium -m-1">
                          {blogItem.tags.map((tagitem, tagindex) => (
                            <li key={tagindex} className="m-1">
                              <Link to={"/blog/tag/" + tagitem.index} className={tagitem.className}>{tagitem.tag}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <h3 className="h4 mb-2">
                        <Link to={"/blog-post" + "/" + blogItem.index} className="hover:text-gray-100 transition duration-150 ease-in-out">{blogItem.title}</Link>
                      </h3>
                    </header>
                    <p className="text-lg text-gray-400 flex-grow">{blogItem.abstract}</p>
                    <footer className="flex items-center mt-4">
                      <Link to={"/blog/by/" + blogItem.author}>
                        <img className="rounded-full hover:border-gray-500 border-2 border-gray-700 flex-shrink-0 mr-4" src={authors[blogItem.author].image} width="40" height="40" alt="Author Image" />
                      </Link>
                      <div className="font-medium">
                        <Link to={"/blog/by/" + blogItem.author} className="text-gray-300 hover:text-gray-100 transition duration-150 ease-in-out">{authors[blogItem.author].name}</Link>
                        <span className="text-gray-700"> - </span>
                        <span className="text-gray-500">{blogItem.date}</span>
                      </div>
                    </footer>
                  </article>
                )
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BlogList;