import React from 'react';
import { Link } from 'react-router-dom';
import { tags, blogs } from './blogindex.js'

export function blogBody() {
    return (
        <div className="text-lg text-gray-400">
            <div>
            <p className="mb-6">
                For the past two years, and counting, the world has been wrestling with COVID-19 and debating the most appropriate pathway back to normality. We have witnessed round after round of dueling experts, posturing politicians, clashing subcultures and well-founded anxiety amongst the general population. In such circumstances it is natural to grasp onto the promise of a quick and easy fix. However, these don’t tend to exist and pursuing them invariably causes more harm than good.
            </p>
            <p className="mb-6">
                Despite the suite of benefits connected cars offer, drivers remain understandably anxious about
                the security risks attached to vehicle connectivity.  According to Deloitte, globally more than 60% of connected
                car drivers in 2021 are concerned about someone hacking their vehicle and creating a
                risk to their safety.
            </p>
            <figure className="md:float ml-6 mt-8 mb-8">
                <img className="mx-auto" src={require('./images/deloitte2021gacs.jpg').default} width="620" alt="Percent Fear by Nation" />
                <figcaption className="text-sm text-center text-gray-500 mt-3">
                    Percentage of consumers who fearing hacking of their connected car risking personal safety<br />
                    (Deloitte 2021 Global Automotive Consumer Study)
                </figcaption>
            </figure>
            </div>
            <div>
            <p className="mb-6">
                In 2019, Consumer Watchdog published an investigative report detailing rising concerns
                surrounding connected vehicles – aptly named the
                &nbsp;
                <a className="text-gray-200 underline hover:no-underline"
                    href='https://www.consumerwatchdog.org/sites/default/files/2019-07/KILL SWITCH  7-29-19.pdf'>
                    “Kill Switch” report
                </a>
                <figure className="md:float-right ml-6 mt-8 mb-4">
                    <img className="mx-auto" src={require('./images/killswitchreport.jpg').default} width="220" alt="KillSwitchReport" />
                    <figcaption className="text-sm text-center text-gray-500 mt-3">The infamous "Kill Switch" report</figcaption>
                </figure>
                &nbsp;
                It argued that including a kill switch that isolates vehicles from connectivity
                would be a cheap and effective solution to the risks of connected vehicle hacking.
                The switch, it was suggested, would add mere cents to the vehicle manufacturing cost
                and provide an effective solution to the risks of hacking.
            </p>
            <p className="mb-6">
                The front page asserted that “connected cars can be killing machines”, alongside several images
                of multi-vehicle accidents. Heady stuff! But let’s pause for a moment to ask: how and why was the
                idea of a kill switch conceived?
            </p>
            <p className="mb-6">
                The upsurge of serious cybersecurity risks grew in unison with the rise of connected vehicles
                (as discussed in our&nbsp;
                <Link className="text-gray-200 underline hover:no-underline"
                    to='/blog-post/0'>
                    previous post
                </Link>
                ), so the ability to kill this connectivity and allow consumers to prevent themselves from being hacked would only be sensible, right? Unfortunately, it’s not that simple, and the kill switch comes laden with drawbacks, as follows.
            </p>
            <h4 className="h4 text-lg mb-1 text-gray-200">1.&nbsp;&nbsp;It creates consumer distrust</h4>
            <p className="mb-6">
                The mere presence of the switch implies that the manufacturer has not been able to sufficiently address the cybersecurity risks and has proceeded with an unsafe design. In discouraging purchasers, the actual impact of the kill switch would not be cents added to each vehicle’s production cost, but thousands of dollars lost on a failed sale.
            </p>
            <h4 className="h4 text-lg mb-1 text-gray-200">2.&nbsp;&nbsp;It only stops “movie” hacks</h4>
            <p className="mb-6">
                The kill switch seems to have been designed to thwart TV and movie hackers, and it would not be very effective against real-world attacks. On the screen, the victim often receives subtle warnings and time to anticipate an attack. The instruments may act strangely, the victim might struggle to gain control the vehicle, the lights inside the cabin might flash on and off. This is fantasy. Genuine attacks are not planned with cinematic tension in mind. Threats may be executed almost instantly without warning. The victim wouldn’t have time to reach the switch, let alone use it to disconnect communications. Real-world attacks rarely rely on ongoing end-to-end connectivity, as depicted by Hollywood. Detecting a real attack and then interrupting the communications while the attack is occurring would do little to prevent harm.
            </p>
            </div>
            <div>
            <figure className="mb-6">
                <img className="w-full" src={require('./images/diediedie.jpg').default} width="768" height="432" alt="News inner" />
                <figcaption className="text-sm text-center text-gray-500 mt-3">TV hackers can't resist telegraphing their hax</figcaption>
            </figure>
            <h4 className="h4 text-lg mb-1 text-gray-200">3.&nbsp;&nbsp;It’s counterproductive</h4>
            <p className="mb-6">
                Critical safety systems rely on vehicle connectivity. Systems like E-Call (Emergency Call Assistance)
                automatically alert emergency services in the event of a collision. A kill switch that severs all
                communications and disables systems like E-Call, would increase the likelihood of death in the event of
                a serious collision by delaying the arrival of emergency services. Connectivity is also improving OEM's
                diagnostic capabilities to identify attacks, and deliver over-the-air real time updates. This allows rapid
                mitigation of vulnerabilities without the expense and inconvenience of vehicle recalls.
            </p>
            <figure className="md:float mt-4 mb-12">
                <img className="mx-auto" src={require('./images/ambulance.jpg').default} width='800' alt="Speeding Ambulance" />
            </figure>
            </div>
            <div>
            <h4 className="h4 text-lg mb-1 text-gray-200">4.&nbsp;&nbsp; There are better solutions</h4>
            <p className="mb-6">
                Standard automtive safety requires supervisory functions that monitor the behaviour of safety critical
                systems. These can also enforce minimal safe behaviour of systems which are exposed to connectivity risks.
                This best-practice attack-tolerant architecture is a clear responsibility in current vehicle cybersecurity
                standards and regulations. Focusing on compliance with these design standards will deliver a more expansive
                and secure solution than a connectivity kill switch.
            </p>

            <h4 className="h4 text-lg mb-1 text-gray-200">Don't shoot the messenger</h4>
            <p className="mb-6">
                Kill switches aren’t the answer. Instead, manufacturers need to embrace connectivity and leverage its positive capabilities. They also need to establish a clear, well communicated strategy that addresses safety and cybersecurity concerns of consumers. Those who can achieve both these things will not only win the trust of consumers, but also gain a significant market advantage.
            </p>
            </div>
        </div>
    );
}