import React from 'react';
import { Link } from 'react-router-dom';
import { tags, blogs } from './blogindex.js'

export function blogBody() {
    return (
        <div className="text-lg text-gray-400">
            <p className="mb-6">
                Like the fictional Dr Jekyll, who by day navigated polite Victorian society, digital connectivity has established order, control and convenience. But by night, the Mr Hydes of this connected world exploit the vulnerabilities created by too much trust.
            </p>
            <p className="mb-6">
                It’s more than 10 years since the seminal&nbsp;
                <a className="text-gray-200 underline hover:no-underline"
                    href='http://www.autosec.org/pubs/cars-usenixsec2011.pdf'>
                    Comprehensive Experimental Analyses of Automotive Attack Surfaces
                </a>
                &nbsp;was presented at 20th USENIX Security Symposium. A result of a two-year collaboration between teams from University of Washington and University of California San Diego, it is credited with starting Charlie Miller and Chris Valasek on their car hacking adventures, who's 2015 jeep hack in turn inspired a new generation of car hackers.
            </p>
            <h4 className="h4 mb-1 text-gray-200">A dangerous intersection</h4>
            <p className="mb-6">
                While hackers and academics had long shown an interest in cars before this (see inset), the stakes changed in the late 2000's with the introduction of drive by wire technology and active safety systems including Emergency Automatic Braking and Lane Keeping Assistance, all making it possible to directly affect vehicle behaviour with dangerous consequences.
            </p>
            <p className="mb-6">
                Vehicles also gained new communications channels, making them even more accessible to the curious. Diagnostics and USB ports, Wi-Fi, Bluetooth, and even Tyre Pressure Management Systems and Digital Broadcast Radio receivers were successfully exploited as avenues of attack.
            </p>
            <div className="border-2 text-sm text-white bg-gray-700 border-gray-700 p-4 py-2 mb-6">
                <p className='mb-2 font-bold'>Car Hacking - the early days:</p>
                <ul className="ml-4 list-disc list-inside">
                    <li>Phrack magazine’s 1993 <a className="text-gray-200 underline hover:no-underline" href='http://phrack.org/issues/43/20.html'>
                        counter-cultural exercise in information redistribution;</a></li>
                    <li>Business opportunities in <a className="text-gray-200 underline hover:no-underline" href='https://www.forbes.com/forbes/2002/0708/148.html'>
                        reverse engineering EFI modules;</a></li>
                    <li><a className="text-gray-200 underline hover:no-underline" href='https://eprint.iacr.org/2010/332.pdf'>
                        Attacks against passive engine immobilizers and RFID keyless systems.</a>
                    </li>
                </ul>
                <p className='mt-2 font-bold'></p>
                {/* <p className='mt-2 font-bold'>See more at the Car Hacking Timeline</p> */}
            </div>
            <figure className="md:float-right ml-6 mt-8 mb-4">
                <img className="mx-auto" src={require('./images/killswitchreport.jpg').default} width="220" alt="Kill Switch Report" />
                <figcaption className="text-sm text-center text-gray-500 mt-3">The infamous "Kill Switch" report</figcaption>
            </figure>
            <h4 className="h4 mb-1 text-gray-200">Accelerating risk</h4>
            <p className="mb-6">
                This combination of an expanding variety of communications channels with increasingly
                dire consequences, has made connected vehicles an attractive target for hackers of all shades.
                These exposures will only increase with the addition of new features and radio&nbsp;

                protocols such as V2X. The ongoing shift to modularization of systems will also add to this
                trend with integration of these new systems accelerating the complexity and usage of communications channels.
            </p>
            <p className="mb-6">
                With such an expanding attack surface, it's tempting to control the risk by
                limiting connectivity, as argued in Consumer Watchdog's&nbsp;
                <a className="text-gray-200 underline hover:no-underline"
                    href='https://www.consumerwatchdog.org/sites/default/files/2019-07/KILL SWITCH  7-29-19.pdf'>
                    "Kill Switch" Report.
                </a>
                &nbsp;Yet this is the cybersecurity equivalent of cutting off one’s nose to spite one’s face.
                It is good practice to remove redundant and low value interfaces,
                but generally limiting connectivity also limits agility and kills features that depend on diverse and flexible communications channels.
            </p>
            <h4 className="h4 mb-1 text-gray-200">The challenging road to a secure future</h4>
            <p className="mb-6">
                Today's connected vehicles present many opportunities both for hackers and security researchers, and still more challenges for cybersecurity engineers.  The technique for addressing these challenges hasn’t changed: careful identification of potential targets and the possible methods of attack, and then systematic risk-based design of appropriate mitigations to prevent the attacks.
            </p>
            <figure className="mb-6">
                <img className="w-full" src={require('./images/tortuous.jpg').default} width="768" height="432" alt="Tortuous Mountain Road" />
            </figure>
            <p className="mb-6">
                What has changed is the extent and complexity of the systems that need to be secured. If the threat modeling and risk analysis process does not scale or is under resourced, then cybersecurity engineers can only consider mitigation strategies at too high a level and produce cybersecurity requirements which are too coarse and therefore limit functionality, or too expensive and get rejected or even just ignored.
            </p>
            <p className="mb-6">
                These trends will place even more pressure on automotive cybersecurity in the next decade. Automotive manufacturers who master this challenge - embracing complexity without compromising on trust - they alone will deliver the dream of connected, convenient mobility beyond the reach of dark actors and their nightmarish attacks on our privacy and safety.
            </p>
        </div>
    );
}