import React from 'react';
import { Link } from 'react-router-dom';
import { tags, blogs } from './blogindex.js'

export function blogBody() {
    return (
        <div className="text-lg text-gray-400">
            <p className="mb-6">
                The world is amid the worst crime epidemic in recorded history. Since the beginning of the COVID-19 pandemic, 
                &nbsp;
                <a className="text-gray-200 underline hover:no-underline"
                    href='https://www.embroker.com/blog/cyber-attack-statistics/'>
                    cybercrime has risen 600%
                </a>
                . In 2015, cybercrime cost companies across the world $3 trillion USD and that number is on track to more than triple to 
                &nbsp;
                <a className="text-gray-200 underline hover:no-underline"
                    href='https://www.globenewswire.com/news-release/2020/11/18/2129432/0/en/Cybercrime-To-Cost-The-World-10-5-Trillion-Annually-By-2025.html#:~:text=Every%20U.S.%20business%20is%20under%20cyberattack&text=18%2C%202020%20(GLOBE%20NEWSWIRE),%243%20trillion%20USD%20in%202015.'>
                    $10.5 trillion USD by 2025
                </a>
                . Cybercrime is driving the greatest transfer of economic wealth in history
            </p>
            <p className="mb-6">
                The rapid digitization of the automotive industry has placed them squarely the sights of the cyber criminals. 
                The last five years has seen a dramatic surge in theft targeting keyless entry systems with sophisticated radio attacks,
                and more recently ransomware attacks against manufacturers and suppliers. Some reports claim automotive cybersecurity 
                attacks have grown by over 500% in this period.
            </p>
            <p className="mb-6">
                The automotive industry is not deaf to this problem, but faces a chronic shortage of cybersecurity skills.
                This is not specific to the industry: according to the 
                &nbsp;
                <a className="text-gray-200 underline hover:no-underline"
                    href='https://www.consumerwatchdog.org/sites/default/files/2019-07/KILL SWITCH  7-29-19.pdf'>
                    International System Security Certification Consortium
                </a>
            
                &nbsp;there remains over 4 million unfilled cybersecurity positions across the world. As widespread attacks increase in both frequency and complexity, the critical need for cybersecurity professionals grows even faster. 
            </p>
            <p className="mb-6">
            What can be done?
            </p>
            <figure className="mb-6">
                <img className="w-full" src={require('./images/seedlings.jpg').default} width="768" height="432" alt="Seedlings in Field" />
            </figure>
            <h4 className="h4 text-lg mb-1 text-gray-200">1.&nbsp;&nbsp;Seed the fields</h4> 
            <p className="mb-2">
                A&nbsp;
                <a className="text-gray-200 underline hover:no-underline"
                    href='https://www.inc.com/joseph-steinberg/millennials-women-and-cybersecurity-new-survey-reveals-an-alarming-trend.html'>
                    US survey 
                </a>
                &nbsp;of 4000 millennials found that 67% of men and 77% of women had never had a 
                high school teacher or careers counsellor mention the idea of a cybersecurity career to them.  
                The EU Agency for Cybersecurity’s&nbsp;
                <a className="text-gray-200 underline hover:no-underline"
                    href='https://www.enisa.europa.eu/publications/addressing-skills-shortage-and-gap-through-higher-education'>
                2021 report 
                </a>
                &nbsp;offered several recommendations:
            </p>
            <ul className="ml-6 mb-4 list-disc list-inside">
                <li>Increase promotion of and enrolment in cybersecurity programmes</li>
                <li>A unified approach across Government, Industry and Universities</li>
                <li>Ongoing monitoring of the cybersecurity market’s trends and needs</li>
            </ul>

            <p className="mb-6">
                Of course, the resulting cybersecurity expertise will be of little use in facing the immediate threats. 
            </p>
            
            <figure className="mb-6">
                <img className="w-full" src={require('./images/sourcelocal.jpg').default} width="768" height="432" alt="Locally Sourced Produce" />
            </figure>
            <h4 className="h4 text-lg mb-1 text-gray-200">2.&nbsp;&nbsp;Source Locally</h4> 
            <p className="mb-6">
                The skill shortage is amplified in industries like automotive which need cybersecurity expertise to be combined with familiarity in established engineering practices. General cybersecurity specialists who do not have established engineering experience find it difficult to integrate their expertise into the product development process. For this reason, automotive companies often find it better to retrain existing engineers as cybersecurity specialists.
            </p>
            <p className="mb-6">
                The EU funded&nbsp;
                <a className="text-gray-200 underline hover:no-underline"
                    href='https://www.project-drives.eu/en/home'>
                Drives Program 
                </a>
                &nbsp;is a good example of an industry coordinated approach to identifying gaps
                in roles and skill levels, and then generating the necessary training programmes. 
                However, a vocational role-based perspective limits its agility - a key ingredient 
                for rapid response to current threats.
            </p>
            
            <figure className="mb-6">
                <img className="w-full" src={require('./images/vinegrafts.jpg').default} width="768" height="432" alt="Vine Grafts" />
            </figure>

            <h4 className="h4 text-lg mb-1 text-gray-200">3.&nbsp;&nbsp;Right Time, Right Place</h4> 
            <p className="mb-6">
                The greatest benefit comes from grafting the cybersecurity skills into the existing engineering 
                roles. Many critical cybersecurity mitigations are not best performed by experts, but by the 
                software and hardware engineers that are building the systems. These are preventive controls 
                that thwart cybersecurity vulnerabilities before they are introduced (as discussed in an&nbsp;
                <Link className="text-gray-200 underline hover:no-underline"
                    to='/blog-post/2'>
                earlier post 
                </Link>
                ).
            </p>
            <p className="mb-6">
                This requires a different kind style of training – not focused on vocational roles, but providing specific skills for the task at hand, delivered just when it is needed.  Such agile cybersecurity training efficiently generates cybersecurity capability exactly when and where it is most effective.
            </p>
        </div>
    );
}