import React from 'react';

function ServicesHero() {
  return (
    <section className="relative">

      {/* Background image */}
      <div className="absolute w-full">
        <div className="max-w-6xl mx-auto px-2 sm:px-4">
          <div className="hidden md:flex float-right justify-end">
            <img className="md:w-4/5 lg:w-full" src={require('../images/kf-8.png').default} alt="Kingfisher diving into water" />
          </div>
        </div>
      </div>
      <div className="md:hidden">
        <img className="" src={require('../images/kf-8.png').default} alt="Kingfisher diving into water" />
      </div>

      {/* Hero content */}
      <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
        <div className="pb-10 lg:pt-48 md:pt-32 md:pb-20">
          <div className="max-w-5xl mt-10 mx-auto text-center md:text-left">
            <h3 className="h3  mt-4 mb-12" data-aos="fade-up">Cybersecurity services</h3>
            <h4 className="h4 mb-0" data-aos="fade-up">Delivered with precision, for maximum effect. </h4>
          </div>
        </div>
      </div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
        <div className="pt-6 pb-4 md:pt-6 md:pb-10">
          <div className="max-w-5xl mx-auto text-center">
            <p className="text-xl text-center md:text-left text-white mb-8" data-aos="fade-up" data-aos-delay="200">
              Cybersecure products depend on mature cybersecurity capabilities, yet these take time to develop.   
              What if an organisation could focus their capability development on those processes and skills that matter most for the product at hand?
            </p>
            <p className="text-xl text-center md:text-left text-white mb-2" data-aos="fade-up" data-aos-delay="200">
              ThreatAgility's cybersecurity dynamics modelling allows our customers to target those processes and capabilities that deliver the most effective cybersecurity outcomes, from development, through the lifetime of the product.
            </p>
          </div>
        </div>
      </div>

      {/* cybersecurity services strategy detail */}
      
      <div className="max-w-5xl mx-auto border-t border-gray-800">
        <div className="py-6 md:py-6">
          <div className="grid gap-20" data-aos-id-features-home>
            <div className="md:grid md:grid-cols-12 md:gap-2 items-center">
              {/* Image */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-6 lg:col-span-5 mb-8 md:mb-0 md:order-0" data-aos="fade-right" data-aos-anchor="[data-aos-id-features-home]">
                <div className="relative">
                  <img className="w-full md:max-w-none md:w-full" src={require('../images/css.png').default} width="400" height="400" alt="Cybersecurity Services Strategy" />
                </div>
              </div>
              {/* Content */}
              <div className="max-w-lg text-center md:text-left md:max-w-none md:w-full mx-auto md:col-span-6 lg:col-span-7" data-aos="fade-left" data-aos-anchor="[data-aos-id-features-home]">
                <div className="md:pl-4 lg:pl-12 xl:pl-16">
                  <p className="text-xl text-gray-400 mb-8">
                    ThreatAgility assesses an organisation's capabilities in the context of   
                    specific product requirements, generating a made-to-measure cybersecurity service strategy.
                  </p>
                  <p className="text-xl text-gray-400 mb-8">
                    This measured approach allows our customers to rapidly deploy urgent capabilities having high impact in 
                    the short term, while mitigating potential failures in product development, and at the same time, proportionately build maturity in critical capabilities with longer term impact.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
        <div className="pt-2 pb-4 md:pt-2 md:pb-10">
          <div className="max-w-5xl mx-auto text-center">
            <p className="text-xl text-center md:text-left text-white mb-8" data-aos="fade-up" data-aos-delay="200">
              Agile delivery of cybersecurity services is demanded by our rapidly evolving industry. 
              ThreatAgility’s high-fidelity modeling and cybersecurity simulation enables precise delivery of 
              cybersecurity, when and where it matters most.      
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServicesHero;