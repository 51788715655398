import React, { useState, useRef, useEffect } from 'react';
import Transition from '../utils/Transition.js';

function Locations() {

  

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-4 md:py-20 border-t border-gray-800">

          {/* Section header */}
          <div className="z-10 relative max-w-4xl mx-auto text-center pb-12 md:pb-16" data-aos-id-testimonialcar>
            <h2 className="h2 mb-8" data-aos="fade-up" data-aos-anchor="[data-aos-id-testimonialcar]">
              A global team for a global industry</h2>
            <p className="text-xl text-gray-400" data-aos="fade-up" data-aos-delay="200" data-aos-anchor="[data-aos-id-testimonialcar]">
              We believe the best teams are built with the right people, wherever they are located.<br/><br/> 
              Some opportunities are restricted to specific locations due to project requirements, <br/> 
              but when you join ThreatAgility, you will be joining a global team,<br/> with global opportunities.
            </p>
          </div>

          
          <div className="flex flex-row justify-center items-center">
            {/* World map */}
            <div className="py-4">
              <div className="relative">
                {/* Map */}
                <img className="relative align-center" src={require('../images/worldmaps.png').default} alt="World map" />
                 <svg style={{ top: '43%', left: '61%' }} className="absolute inset-0 max-w-full mx-auto md:max-w-none h-auto" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <g className="fill-current text-red-700"><circle cx="10" cy="10" r="5"/></g>
                </svg>
                <svg style={{ top: '60%', left: '51.5%' }} className="absolute inset-0 max-w-full mx-auto md:max-w-none h-auto" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <g className="fill-current text-red-700"><circle cx="10" cy="10" r="3"/></g>{/*singapore*/}
                </svg>
                <svg style={{ top: '48.5%', left: '52%' }} className="absolute inset-0 max-w-full mx-auto md:max-w-none h-auto" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <g className="fill-current text-red-700"><circle cx="10" cy="10" r="3"/></g>{/*vietnam*/}
                </svg>
                <svg style={{ top: '81%', left: '78%' }} className="absolute inset-0 max-w-full mx-auto md:max-w-none h-auto" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <g className="fill-current text-red-700"><circle cx="10" cy="10" r="3"/></g>{/*sydney*/}
                </svg>
                <svg style={{ top: '30.5%', left: '-0.2%' }} className="absolute inset-0 max-w-full mx-auto md:max-w-none h-auto" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <g className="fill-current text-red-700"><circle cx="10" cy="10" r="3"/></g>{/*munich*/}
                </svg>
                <svg style={{ top: '28.5%', left: '2%' }} className="absolute inset-0 max-w-full mx-auto md:max-w-none h-auto" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <g className="fill-current text-red-700"><circle cx="10" cy="10" r="3"/></g>{/*berlin*/}
                </svg>
                <svg style={{ top: '32%', left: '-74%' }} className="absolute inset-0 max-w-full mx-auto md:max-w-none h-auto" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <g className="fill-current text-red-700"><circle cx="10" cy="10" r="3"/></g>{/*seattle*/}
                </svg>
                <svg style={{ top: '40%', left: '-73%' }} className="absolute inset-0 max-w-full mx-auto md:max-w-none h-auto" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <g className="fill-current text-red-700"><circle cx="10" cy="10" r="3"/></g>{/*sfla*/}
                </svg>
                <svg style={{ top: '37%', left: '6%' }} className="absolute inset-0 max-w-full mx-auto md:max-w-none h-auto" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <g className="fill-current text-red-700"><circle cx="10" cy="10" r="3"/></g>{/*greece*/}
                </svg>
                {/* <img className="absolute rounded-full" style={{ top: '22%', left: '52%' }} src={require('../images/world-user-01.jpg').default} width="32" height="32" alt="User 01" data-aos="fade-up" />
                <img className="absolute rounded-full" style={{ top: '50%', left: '58%' }} src={require('../images/world-user-02.jpg').default} width="48" height="48" alt="User 02" data-aos="fade-up" data-aos-delay="400" />
                <img className="absolute rounded-full" style={{ top: '8.5%', left: '70.5%' }} src={require('../images/world-user-03.jpg').default} width="56" height="56" alt="User 02" data-aos="fade-up" data-aos-delay="100" />
                <img className="absolute rounded-full" style={{ top: '34%', left: '76.5%' }} src={require('../images/world-user-04.jpg').default} width="72" height="72" alt="User 04" data-aos="fade-up" data-aos-delay="700" />
                <img className="absolute rounded-full" style={{ top: '29.5%', left: '8.5%' }} src={require('../images/world-user-05.jpg').default} width="48" height="48" alt="User 05" data-aos="fade-up" data-aos-delay="500" />
                <img className="absolute rounded-full" style={{ top: '56%', left: '19%' }} src={require('../images/world-user-06.jpg').default} width="56" height="56" alt="User 06" data-aos="fade-up" data-aos-delay="200" />
                <img className="absolute rounded-full" style={{ top: '20%', left: '24.5%' }} src={require('../images/world-user-07.jpg').default} width="64" height="64" alt="User 07" data-aos="fade-up" data-aos-delay="600" />
                <img className="absolute rounded-full" style={{ top: '39%', left: '43%' }} src={require('../images/world-user-08.jpg').default} width="40" height="40" alt="User 08" data-aos="fade-up" data-aos-delay="300" /> */}
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

export default Locations;